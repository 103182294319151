import React from "react";
import theme from "theme";
import { Theme, Text, Section, Box, Link, Image } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"services"} />
		<Helmet>
			<title>
				Über uns | K13 Marketing
			</title>
			<meta name={"description"} content={"Visionen in die Realität umsetzen"} />
			<meta property={"og:title"} content={"Über uns | K13 Marketing"} />
			<meta property={"og:description"} content={"Visionen in die Realität umsetzen"} />
			<meta property={"og:image"} content={"https://glaricorn.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://glaricorn.com/img/43658675681.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://glaricorn.com/img/43658675681.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://glaricorn.com/img/43658675681.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://glaricorn.com/img/43658675681.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://glaricorn.com/img/43658675681.png"} />
			<meta name={"msapplication-TileImage"} content={"https://glaricorn.com/img/43658675681.png"} />
			<meta name={"msapplication-TileColor"} content={"https://glaricorn.com/img/43658675681.png"} />
		</Helmet>
		<Components.Header />
		<Section
			text-align="center"
			background-color="--primary"
			color="--light"
			padding="80px 0"
			sm-padding="40px 0"
		>
			<Text
				as="h5"
				font="--lead"
				margin="10px 0 0 0"
				text-transform="uppercase"
				letter-spacing="5px"
			>
				K13 Marketing Agency Services
			</Text>
			<Text as="h1" font="--headline1" md-font="--headline2" margin="10px 0 0 0">
			Entdecken Sie unser umfassendes Angebot
			</Text>
			<Text as="p" font="--lead" margin="40px 0 20px 0">
			Bei der K13 Marketing Agency bieten wir eine umfassende Palette von Dienstleistungen an, die Ihre Marke aufwerten und Ihr Geschäft voranbringen sollen. Unsere Expertise erstreckt sich über verschiedene Marketingbereiche und stellt sicher, dass alle Ihre Anforderungen unter einem Dach erfüllt werden. Von der strategischen Planung bis zur kreativen Umsetzung sind wir Ihre Partner für den Erfolg.
			</Text>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Box
				display="grid"
				lg-flex-wrap="wrap"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="36px 4%"
				lg-grid-template-columns="repeat(2, 1fr)"
				sm-grid-template-columns="1fr"
				sm-grid-gap="12px"
			>
				<Box display="flex" flex-direction="column" sm-margin="0px 0px 30px 0px" sm-width="100%">
					<Text
						padding="7px 24px 8px 24px"
						margin="0px 0px 32px 0px"
						font="--headline3"
						border-radius="50px"
						align-items="center"
						display="flex"
						justify-content="center"
						color="--light"
						width="49px"
						height="49px"
						background="--color-primary"
						sm-margin="0px 0px 20px 0px"
						lg-margin="0px 0px 18px 0px"
					>
						1
					</Text>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
					Unser Serviceangebot
					</Text>
					<Text margin="0px 0px 0px 0px" color="--greyD2" font="--base" lg-text-align="left">
					Marktanalyse: Verstehen Sie Ihre Zielgruppe und Ihre Konkurrenten, um Ihre Marketingstrategie zu untermauern.
					<br/><br/>
					Zielsetzung: Definieren Sie klare, erreichbare Ziele, um Ihre Marketingbemühungen zu steuern.
					<br/><br/>
					Kampagnenplanung: Entwickeln Sie umfassende Marketingkampagnen, die mit Ihren Geschäftszielen übereinstimmen.
					<br/><br/>
					Leistungsmetriken: Festlegen von Key Performance Indicators (KPIs), um den Erfolg Ihrer Kampagnen zu messen.
					</Text>
				</Box>
				<Box display="flex" flex-direction="column" sm-width="100%" sm-margin="0px 0px 30px 0px">
					<Text
						padding="7px 24px 8px 24px"
						margin="0px 0px 32px 0px"
						font="--headline3"
						border-radius="50px"
						align-items="center"
						display="flex"
						justify-content="center"
						color="--light"
						width="49px"
						height="49px"
						background="--color-primary"
						sm-margin="0px 0px 20px 0px"
						lg-margin="0px 0px 18px 0px"
					>
						2
					</Text>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
					Digitales Marketing
					</Text>
					<Text margin="0px 0px 0px 0px" color="--greyD2" font="--base" lg-text-align="left">
					On-Page-SEO: Optimieren Sie Inhalt und Struktur Ihrer Website für ein besseres Ranking in Suchmaschinen.
					<br/><br/>
Linkaufbau: Erstellen Sie hochwertige Backlinks, um die Autorität Ihrer Website zu verbessern.
					<br/><br/>
Suchmaschinenmarketing (SEM): Verwenden Sie bezahlte Werbung, um Traffic und Conversions zu steigern.
					<br/><br/>
Werbetexterstellung: Erstellen Sie überzeugende Anzeigen, die Klicks und Conversions generieren.

					</Text>
				</Box>
				<Box display="flex" flex-direction="column" sm-margin="0px 0px 30px 0px" sm-width="100%">
					<Text
						padding="7px 24px 8px 24px"
						margin="0px 0px 32px 0px"
						font="--headline3"
						border-radius="50px"
						align-items="center"
						display="flex"
						justify-content="center"
						color="--light"
						width="49px"
						height="49px"
						background="--color-primary"
						sm-margin="0px 0px 20px 0px"
						lg-margin="0px 0px 18px 0px"
					>
						3
					</Text>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
					Markenentwicklung
					</Text>
					<Text margin="0px 0px 0px 0px" color="--greyD2" font="--base" lg-text-align="left">
					Markenbotschaft: Entwickeln einer konsistenten Botschaft, die bei Ihrem Publikum ankommt.
					<br/><br/>
Taglines und Slogans: Erstellen einprägsamer und einprägsamer Sätze.
					<br/><br/>
Stimme und Ton: Definieren der Stimme und des Tons der Kommunikation Ihrer Marke.
					<br/><br/>
Storytelling: Erstellen überzeugender Markengeschichten, die Ihr Publikum fesseln.

					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="50px 0 50px 0" background="#EDF2F6" sm-padding="60px 0 60px 0">
			<Box
				width="100%"
				display="flex"
				flex-direction="column"
				md-width="100%"
				md-align-items="center"
				md-justify-content="center"
				md-text-align="center"
				lg-width="100%"
				margin="0px 0px 30px 0px"
				align-items="center"
			>
				<Text
					margin="0px 0px 16px 0px"
					font="--headline2"
					color="--dark"
					md-text-align="center"
					sm-font="normal 700 32px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					text-align="center"
				>
					Kreatives Design
				</Text>
			</Box>
			<Box display="grid" grid-template-columns="repeat(3, 1fr)" grid-gap="32px 4%" md-grid-template-columns="1fr">
				<Box
					padding="30px 30px 30px 30px"
					lg-padding="45px 30px"
					md-padding="45px 45px"
					background="#FFFFFF"
					border-radius="24px"
					justify-content="flex-start"
					flex-direction="column"
					display="flex"
				>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
					Website-Design
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD3"
						font="--base"
						lg-text-align="left"
						flex="1 0 auto"
					>
						Erstellen benutzerfreundlicher Websites, die das Engagement fördern.
					</Text>
				</Box>
				<Box
					padding="30px 30px 30px 30px"
					lg-padding="45px 30px"
					md-padding="45px 45px"
					background="#FFFFFF"
					border-radius="24px"
					justify-content="flex-start"
					flex-direction="column"
					display="flex"
				>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
					Responsive Design
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD3"
						font="--base"
						lg-text-align="left"
						flex="1 0 auto"
					>
						Sicherstellen, dass Ihre Website auf allen Geräten gut aussieht.
					</Text>
				</Box>
				<Box
					padding="30px 30px 30px 30px"
					lg-padding="45px 30px"
					md-padding="45px 45px"
					background="#FFFFFF"
					border-radius="24px"
					justify-content="flex-start"
					flex-direction="column"
					display="flex"
				>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
					UX/UI-Design
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD3"
						font="--base"
						lg-text-align="left"
						flex="1 0 auto"
					>
						Erstellen intuitiver und angenehmer Benutzererfahrungen.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				padding="0px 160px 0px 0px"
				border-radius="24px"
				margin="0px 0px 0px 0px"
				width="50%"
				lg-padding="0px 50px 0px 0px"
				lg-width="50%"
				md-width="100%"
				md-padding="0px 0px 0px 0px"
				display="flex"
				flex-direction="column"
				align-items="flex-start"
				md-margin="0px 0px 60px 0px"
				md-align-items="center"
			>
				<Text
					margin="0px 0px 16px 0px"
					font="--headline2"
					color="--dark"
					md-text-align="center"
					sm-font="normal 700 32px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					Fangen wir an
				</Text>
				<Text
					margin="0px 0px 40px 0px"
					font="--base"
					color="--greyD3"
					md-text-align="center"
					md-margin="0px 0px 20px 0px"
				>
					Sind Sie bereit, Ihr Marketing auf ein neues Niveau zu heben? Kontaktieren Sie K13 noch heute und entdecken Sie den Unterschied, den unsere Expertise ausmachen kann. Lassen Sie uns diese Reise gemeinsam antreten, Ihre Marke auf ein neues Niveau heben und beispiellosen Erfolg erzielen.
				</Text>
				<Link
					href="/contact-us"
					text-decoration-line="initial"
					color="--light"
					font="--lead"
					hover-opacity="0.9"
					background="--color-primary"
					border-radius="8px"
					padding="11px 24px 12px 24px"
				>
					Kontakte
				</Link>
			</Box>
			<Box
				display="flex"
				width="50%"
				justify-content="center"
				overflow-y="hidden"
				overflow-x="hidden"
				lg-width="100%"
			>
				<Image
					src="https://glaricorn.com/img/2.jpg"
					width="555px"
					max-width="100%"
					transform="translateY(10px)"
					transition="transform 0.5s ease-in-out 0s"
					hover-transform="translateY(0px)"
					sm-width="100%"
				/>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});